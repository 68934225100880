<template>
  <div class="admin-classrooms-list">
    <v-row justify="center">
      <v-col md="6">
        <intract-smart-list
          class="mt-4"
          ref="admin-classrooms-smart-list"
          :endpoint="endpoint"
        >
          <template v-slot:list-item="{ item: classroom }">
            <v-list-item
              :to="{
                name: 'ClassroomDetails',
                params: { roomId: classroom.id },
              }"
              class="non-focused"
            >
              <v-list-item-content>
                <v-list-item-title>{{ classroom.sections }}</v-list-item-title>
                <v-list-item-subtitle
                  >{{ classroom.total_students }} Students</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-icon
                ><v-icon>mdi-arrow-right</v-icon></v-list-item-icon
              >
            </v-list-item>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import IntractSmartList from "@components/generics/IntractSmartList";
import Mixins from "@utils/mixins";

export default {
  name: "AdminClassroomsList",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],

  components: {
    IntractSmartList,
  },
  
  data() {
    return {};
  },
  computed: {
    endpoint() {
      return this.Helper.addUrlParams(
        this.endpoints.classroomViewSet,
        ["include_counts=true","hide_from_app=false"]
      );
    },

    appBarOptions() {
      return {
        actions: [
          {
            id: 1,
            title: "Manage Extra Subjects",
            icon: "mdi-book-plus",
            onClick: () => (this.$router.push({ name: 'ManageExtraSubjects'})),
          },
        ],
      };
    },
  },
  methods: {
    async onRefresh() {
      this.$refs["admin-classrooms-smart-list"].getItems();
    },
  },

  // beforeRouteLeave(to, from, next) {
  //   if (to.name != "ManageExtraSubjects") {
  //     console.log("destroying");
  //     // this.$destroy();
  //     EventBus.$emit("keep_alive__destroy_view", "AdminClassroomList");
  //   }
  //   next();
  // },

};
</script>